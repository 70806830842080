<template>
  <section id="whatWeDo" class="py-16">
    <div class="container">
      <span class="bg-primary py-2 px-6 text-white font-bold">
        What We Do
      </span>
      <h2
        class="text-xl sm:text-4xl lg:text-6xl font-bold mt-3 mb-16 max-w-2xl"
      >
        Our services turn your vision for the future into reality.
      </h2>
      <div class="flex flex-wrap sm:-mx-2">
        <div
          v-for="(item, index) in items"
          :key="index"
          class="w-full flex sm:w-1/2 sm:px-2 mb-12"
        >
          <div class="flex flex-col relative">
            <img
              class="h-10 w-10"
              style="position: absolute; left: -12px; top: -12px; z-index: -10;"
              :src="require(`@/assets/images/${item.image}.png`)"
              :alt="item.image"
            />
            <h3 class="font-bold text-xl">{{ item.title }}</h3>
            <p class="leading-relaxed mt-1">{{ item.content }}</p>
          </div>
        </div>
      </div>
      <p
        class="text-center max-w-xl mx-auto text-xl sm:text-3xl mt-6 text-grey font-bold"
      >
        We do these for SMEs, Startups, and Government Agencies.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'WhatWeDoSection',
  data() {
    return {
      items: [
        {
          title: 'Consultancy',
          content:
            'Nothing is new under the sun but some things, insights, patterns are hidden. Together we work with you to reveal them.',
          image: 'img_2'
        },
        {
          title: 'Research',
          content:
            "Ego isn't our strong suit. So we research. We go for understanding. We don't know it all but we peep at what data suggests with a sprinkle of a gut feeling.",
          image: 'img_3'
        },
        {
          title: 'Product Design',
          content:
            'We perfectly blend imagination, inspiration, function and relevance to produce interfaces worth experiencing.',
          image: 'img_1'
        },
        {
          title: 'Engineering',
          content:
            'Thinking is our super power. With it, we engineer software , architect systems and manage complex projects with precision.',
          image: 'img_5'
        },
        {
          title: 'Support and Maintenance',
          content:
            'We are in for the long run. Things break, visions get pivoted. For built things to last, we offer support and maintenance plans.',
          image: 'img_4'
        },
        {
          title: 'Ideation',
          content:
            'Sometimes you don’t know where to start. You know there’s an opportunity, we help shape the discovery of the idea-opportunity fit..',
          image: 'img_6'
        }
      ]
    }
  }
}
</script>
