import Vue from 'vue'
import App from './App.vue'
import router from './router'

import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'This field is required'
})
extend('email', {
  ...email,
  message: 'The {_field_} must be a valid email'
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

import UiButton from '@/components/ui/UiButton'
import UiTextInput from '@/components/ui/UiTextInput'

Vue.component('UiButton', UiButton)
Vue.component('UiTextInput', UiTextInput)

const VueScrollTo = require('vue-scrollto')
Vue.use(VueScrollTo, {
  duration: 600,
  easing: 'linear'
})

import AOS from 'aos'
import 'aos/dist/aos.css'

import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
const options = {
  confirmButtonColor: '#FD541E',
  buttonsStyling: false,
  customClass: {
    title: 'alert-title',
    content: 'alert-content',
    confirmButton: 'alert-button',
    cancelButton: 'alert-button-cancel'
  }
}
Vue.use(VueSweetalert2, options)

Vue.config.productionTip = false

new Vue({
  created() {
    AOS.init({ once: false })
  },
  router,
  render: h => h(App)
}).$mount('#app')
