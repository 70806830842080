<template>
  <section class="py-32">
    <div class="container">
      <p
        class="text-center max-w-3xl mx-auto leading-relaxed font-bold text-3xl sm:text-5xl"
      >
        If you’re looking for fast, nimble and agile. We’ve got you. We deliver
        in weeks, not months.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'WordSection'
}
</script>
