<template>
  <nav class="w-full fixed top-0 h-16 bg-dark z-30 shadow">
    <div class="container h-16 flex items-center justify-between">
      <div class="flex items-center">
        <router-link class="border-none" to="/">
          <img
            src="@/assets/images/logo.png"
            alt="Emergent Labs Logo"
            class="h-5"
          />
        </router-link>
      </div>
      <div class="flex items-center ml-5">
        <ul class="hidden md:flex items-center">
          <li class="mr-10 text-white">
            <a
              @click="handleScroll('whatWeDo')"
              href="#"
              v-scroll-to="'#whatWeDo'"
              class="text-sm block py-2 px-4"
            >
              What We Do
            </a>
          </li>
          <li class="mr-10 text-white">
            <router-link class="text-sm block py-2 px-4" to="/projects">
              Projects
            </router-link>
          </li>
        </ul>
        <ui-button @click="handleScroll('contact')" primary small>
          Talk to us
        </ui-button>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavHeader',
  computed: {
    currentRoute() {
      return this.$route.name
    }
  },
  methods: {
    handleScroll(path) {
      if (path === 'whatWeDo') {
        if (this.currentRoute === 'Project') {
          this.$router.push('/')
        } else {
          this.$scrollTo('#whatWeDo')
        }
      } else if (path === 'contact') {
        if (this.currentRoute === 'Project') {
          this.$router.push('/')
        } else {
          this.$scrollTo('#contact')
        }
      }
    }
  }
}
</script>
