var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{class:{ 'cursor-not-allowed': _vm.disabled },attrs:{"vid":_vm.vid,"rules":_vm.rules,"name":_vm.name || _vm.label,"disabled":_vm.disabled,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
return [_c('label',{staticClass:"label",attrs:{"for":_vm.name},on:{"click":function($event){return _vm.$refs.input.focus()}}},[_c('span',[_vm._v(_vm._s(_vm.label))])]),((_vm.type)==='checkbox')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.innerValue),expression:"innerValue"}],ref:"input",staticClass:"input mb-1",class:{
      'border-red-600': errors[0],
      'has-value': _vm.hasValue
    },attrs:{"id":_vm.name,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"maxlength":_vm.maxLength,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.innerValue)?_vm._i(_vm.innerValue,null)>-1:(_vm.innerValue)},on:{"keyup":function($event){return _vm.$emit('keyup')},"blur":function($event){return _vm.$emit('blur')},"keypress":function($event){return _vm.$emit('keypress')},"change":function($event){var $$a=_vm.innerValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.innerValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.innerValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.innerValue=$$c}}}},'input',ariaInput,false)):((_vm.type)==='radio')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.innerValue),expression:"innerValue"}],ref:"input",staticClass:"input mb-1",class:{
      'border-red-600': errors[0],
      'has-value': _vm.hasValue
    },attrs:{"id":_vm.name,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"maxlength":_vm.maxLength,"type":"radio"},domProps:{"checked":_vm._q(_vm.innerValue,null)},on:{"keyup":function($event){return _vm.$emit('keyup')},"blur":function($event){return _vm.$emit('blur')},"keypress":function($event){return _vm.$emit('keypress')},"change":function($event){_vm.innerValue=null}}},'input',ariaInput,false)):_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.innerValue),expression:"innerValue"}],ref:"input",staticClass:"input mb-1",class:{
      'border-red-600': errors[0],
      'has-value': _vm.hasValue
    },attrs:{"id":_vm.name,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"maxlength":_vm.maxLength,"type":_vm.type},domProps:{"value":(_vm.innerValue)},on:{"keyup":function($event){return _vm.$emit('keyup')},"blur":function($event){return _vm.$emit('blur')},"keypress":function($event){return _vm.$emit('keypress')},"input":function($event){if($event.target.composing){ return; }_vm.innerValue=$event.target.value}}},'input',ariaInput,false)),(errors[0])?_c('span',_vm._b({staticClass:"error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }