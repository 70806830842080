<template>
  <footer class="bg-dark pt-8 pb-40">
    <div class="container">
      <div class="flex items-center justify-center flex-wrap">
        <div class="w-full flex sm:w-1/2 md:w-1/3 mb-5">
          <img
            src="@/assets/images/logo_grey.png"
            alt="Emergent labs Logo"
            class="h-6 block"
          />
        </div>
        <div
          class="w-full flex items-center sm:justify-center sm:w-1/2 md:w-1/3 mb-5"
        >
          <p class="text-grey">+2348135949184</p>
        </div>
        <div
          class="w-full flex items-center justify-start md:justify-end sm:w-1/2 md:w-1/3 mb-5"
        >
          <p class="text-grey">info@emergentlabs.com.ng</p>
        </div>
      </div>
      <div class="flex flex-wrap items-center justify-between mt-20">
        <p class="text-grey mb-4 sm:mb-0">
          &copy; {{ getCurrentYear }} Emergent Labs. All Rights Reserved
        </p>
        <!-- <div class="flex justify-center">
          <ul class="flex items-center space-x-8">
            <li v-for="(link, index) in links" :key="index">
              <a :href="link.url" target="_blank">
                <i
                  class="text-white text-lg hover:text-primary"
                  :class="link.icon"
                />
              </a>
            </li>
          </ul>
        </div> -->
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'NavFooter',
  data() {
    return {
      links: [
        {
          url: '/',
          icon: 'fab fa-twitter'
        },
        {
          url: '/',
          icon: 'fab fa-facebook-f'
        },
        {
          url: '/',
          icon: 'fab fa-instagram'
        }
      ]
    }
  },
  computed: {
    getCurrentYear() {
      return new Date().getFullYear()
    }
  }
}
</script>
