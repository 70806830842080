<template>
  <div>
    <nav-header />
    <hero-section />
    <what-we-do-section />
    <brand-section />
    <project-done-section />
    <word-section />
    <contact-section />
    <nav-footer />
  </div>
</template>

<script>
// @ is an alias to /src
import NavHeader from '@/components/navigation/NavHeader'
import HeroSection from '@/components/sections/HeroSection'
import WhatWeDoSection from '@/components/sections/WhatWeDoSection'
import BrandSection from '@/components/sections/BrandSection'
import ProjectDoneSection from '@/components/sections/ProjectDoneSection'
import WordSection from '@/components/sections/WordSection'
import ContactSection from '@/components/sections/ContactSection'
import NavFooter from '@/components/navigation/NavFooter'

export default {
  name: 'Home',
  components: {
    NavHeader,
    HeroSection,
    WhatWeDoSection,
    BrandSection,
    ProjectDoneSection,
    WordSection,
    ContactSection,
    NavFooter
  }
}
</script>
