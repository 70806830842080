<template>
  <section class="py-16 bg-primary">
    <div class="container">
      <p
        class="text-center max-w-xl mx-auto leading-relaxed font-bold text-4xl lg:text-5xl text-white mb-8"
      >
        We’ve worked with notable businesses to bring about their vision for a
        better future.
      </p>
      <div class="flex items-center justify-center flex-wrap -mx-2">
        <div
          v-for="(brand, index) in brands"
          :key="index"
          class="w-1/2 sm:w-1/3 md:w-1/6 px-2 mb-8"
        >
          <img
            class="h-10 block mx-auto"
            data-aos="zoom-in"
            data-aos-duration="800"
            data-aos-easing="ease"
            :src="require(`@/assets/images/${brand.brand}`)"
            :alt="brand.name"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BrandSection',
  data() {
    return {
      brands: [
        { name: 'Jblood Match', brand: 'blood_match.png' },
        { name: 'USAID', brand: 'usaid.png' },
        { name: 'GIC', brand: 'gic.png' },
        { name: 'Foodjaar', brand: 'foodjaar.png' },
        { name: 'Fruity Life', brand: 'fruitylife.png' },
        { name: 'Jhpiego', brand: 'jhp.png' }
      ]
    }
  }
}
</script>
