<template>
  <section id="projects" class="py-16 bg-dark">
    <div class="container">
      <h2
        class="text-center max-w-lg mx-auto leading-relaxed font-bold text-2xl sm:text-4xl text-white mb-12"
      >
        Take a look at some of our recent projects.
      </h2>
      <div class="flex flex-wrap sm:-mx-3">
        <div
          v-for="(item, index) in projects"
          :key="index"
          class="w-full flex justify-center sm:w-1/2 sm:px-3 mb-8"
        >
          <div class="w-full sm:w-70 flex flex-col items-center justify-center">
            <div
              class="flex items-center justify-center py-6 px-5 w-full sm:w-70 sm:h-72"
              :class="[
                index === 0
                  ? 'bg-dark-blue'
                  : index === 1
                  ? 'bg-secondary sm:mt-16'
                  : index === 2
                  ? 'bg-dark-brown'
                  : index === 3
                  ? 'bg-primary sm:mt-16'
                  : 'bg-white'
              ]"
            >
              <img
                class="h-48 block mx-auto"
                :src="require(`@/assets/images/${item.image}`)"
                :alt="item.title"
              />
            </div>
            <h3 class="font-bold text-xl text-white mt-2 self-start">
              {{ item.title }}
            </h3>
            <p class="leading-relaxed mt-1 text-grey mb-2">
              {{ item.content }}
            </p>
            <a
              v-if="item.link !== ''"
              class="text-primary self-start"
              :href="item.link"
              target="_blank"
            >
              View Site
            </a>
          </div>
        </div>
      </div>
      <div class="mt-8 flex items-center justify-center">
        <router-link to="/projects" class="btn btn-primary">
          See All Projects
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ProjectDoneSection',
  data() {
    return {
      projects: [
        {
          title: 'Fruitylife',
          content: 'Ecommerce app for healthy food options.',
          image: 'project_logo_1.png',
          link: 'https://fruitylife.com.ng/'
        },
        {
          title: 'Sendy',
          content: 'Automate recurring airtime for business, project teams.',
          image: 'project_logo_2.png',
          link: 'https://sendy.tinylabs.app/'
        },
        {
          title: 'Getchange',
          content: 'Accept change as airtime or mobile money.',
          image: 'project_logo_3.png',
          link: 'https://getchange.tinylabs.app/'
        },
        {
          title: 'Ryddl',
          content: 'Take short quizzes, learn new things and get rewarded.',
          image: 'project_logo_4.png',
          link: 'http://ryddl.com/'
        }
      ]
    }
  }
}
</script>
