var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',_vm._g({staticClass:"block btn",class:{
    'py-2': _vm.small,
    'py-5': _vm.large,
    'btn-primary': _vm.primary,
    'btn-secondary': _vm.secondary,
    'btn-outline': _vm.outline,
    'shadow-btn': _vm.raised,
    'rounded-lg': _vm.rounded,
    'rounded-full': _vm.roundedFull,
    'w-full': _vm.full,
    'mx-auto': _vm.center,
    'flex items-center justify-center': _vm.loading
  },attrs:{"loading":_vm.loading,"rounded":_vm.rounded,"roundedFull":_vm.roundedFull,"raised":_vm.raised,"full":_vm.full,"small":_vm.small,"primary":_vm.primary,"secondary":_vm.secondary,"outline":_vm.outline,"center":_vm.center,"type":_vm.type,"disabled":_vm.disabled}},_vm.$listeners),[_vm._t("default",[_vm._v("Submit")]),(_vm.loading)?_c('div',{staticClass:"spin h-5 w-5 rounded-full ml-3"}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }