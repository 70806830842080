<template>
  <section class="h-90 relative flex flex-col items-center justify-center">
    <div
      class="video-container absolute top-0 left-0 w-full h-full overflow-hidden"
    >
      <video src="@/assets/video.mp4" autoplay muted loop></video>
    </div>
    <div class="container z-10">
      <h1
        class="text-center text-white font-bold text-3xl md:text-6xl max-w-xl mx-auto"
      >
        We
        <span
          class="bg-clip-text text-transparent bg-gradient-to-r from-blue-900 via-secondary to-primary"
        >
          build
        </span>
        cool stuff out of your<span
          class="bg-clip-text text-transparent bg-gradient-to-r from-blue-900 via-secondary to-primary"
        >
          ideas.
        </span>
      </h1>
      <p class="text-center mt-3 text-grey sm:text-2xl max-w-2xl mx-auto">
        Beyond ideas, we solve for purpose and impact. Product design and
        engineering are simply tools to achieve this.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HeroSection'
}
</script>

<style scoped>
video {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}
</style>
