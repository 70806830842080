var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"py-16 bg-dark",attrs:{"id":"projects"}},[_c('div',{staticClass:"container"},[_c('h2',{staticClass:"text-center max-w-lg mx-auto leading-relaxed font-bold text-2xl sm:text-4xl text-white mb-12"},[_vm._v(" Take a look at some of our recent projects. ")]),_c('div',{staticClass:"flex flex-wrap sm:-mx-3"},_vm._l((_vm.projects),function(item,index){return _c('div',{key:index,staticClass:"w-full flex justify-center sm:w-1/2 sm:px-3 mb-8"},[_c('div',{staticClass:"w-full sm:w-70 flex flex-col items-center justify-center"},[_c('div',{staticClass:"flex items-center justify-center py-6 px-5 w-full sm:w-70 sm:h-72",class:[
              index === 0
                ? 'bg-dark-blue'
                : index === 1
                ? 'bg-secondary sm:mt-16'
                : index === 2
                ? 'bg-dark-brown'
                : index === 3
                ? 'bg-primary sm:mt-16'
                : 'bg-white'
            ]},[_c('img',{staticClass:"h-48 block mx-auto",attrs:{"src":require(("@/assets/images/" + (item.image))),"alt":item.title}})]),_c('h3',{staticClass:"font-bold text-xl text-white mt-2 self-start"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',{staticClass:"leading-relaxed mt-1 text-grey mb-2"},[_vm._v(" "+_vm._s(item.content)+" ")]),(item.link !== '')?_c('a',{staticClass:"text-primary self-start",attrs:{"href":item.link,"target":"_blank"}},[_vm._v(" View Site ")]):_vm._e()])])}),0),_c('div',{staticClass:"mt-8 flex items-center justify-center"},[_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":"/projects"}},[_vm._v(" See All Projects ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }