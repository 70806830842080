<template>
  <section id="contact" class="py-16 bg-dark">
    <div class="container">
      <div class="flex flex-wrap sm:-mx-2">
        <div class="w-full sm:w-1/2 sm:px-2 mb-4">
          <h2
            class="text-2xl sm:text-4xl lg:text-6xl font-bold max-w-md text-white"
          >
            Talk to us, we’re nice.
          </h2>
          <p class="max-w-md mt-2 text-gray-200">
            You can reach out to us by filling the form below. It should only
            take a minute.
          </p>
        </div>
        <div class="w-full sm:w-1/2 sm:px-2">
          <validation-observer ref="form" v-slot="{ passes }" slim>
            <form class="bg-white py-6 px-6" @submit.prevent="passes(onSubmit)">
              <ui-text-input
                v-model="name"
                rules="required"
                label="Your Name"
                name="name"
                type="text"
              />
              <ui-text-input
                v-model="email"
                rules="required|email"
                label="Your Email"
                name="email"
                type="email"
                class="mt-3"
              />
              <ui-text-input
                v-model="WhatToBuild"
                rules="required"
                label="What would you like to build?"
                name="what to build"
                type="text"
                class="mt-3"
              />
              <ui-text-input
                v-model="urls"
                rules="required"
                label="Paste one or two URLs that does similar stuff"
                name="url"
                type="text"
                class="mt-3"
              />
              <div class="mt-3">
                <label for="startDate" class="label">
                  When would you like to start?
                </label>
                <date-picker
                  v-model="startTime"
                  input-class="input cursor-pointer"
                  value-type="format"
                  format="YYYY-MM-DD"
                  type="date"
                />
              </div>
              <div class="mt-3">
                <label for="startDate" class="label">
                  How soon do you want your product/project to go live?
                </label>
                <date-picker
                  v-model="howSoon"
                  input-class="input cursor-pointer"
                  value-type="format"
                  format="YYYY-MM-DD"
                  type="date"
                />
              </div>
              <ui-text-input
                v-model.number="budget"
                rules="required"
                label="What’s your budget?"
                name="budget"
                type="number"
                class="mt-3"
              />
              <vue-recaptcha
                ref="recaptcha"
                @verify="onCaptchaVerified"
                @expired="onCaptchaExpired"
                size="invisible"
                sitekey="6LcdrdwZAAAAAMDc8XT4IRDb5okG-jWWqz2oadYc"
                :loadRecaptchaScript="true"
              >
              </vue-recaptcha>
              <ui-button
                primary
                small
                :loading="loading"
                :disabled="loading"
                class="mt-10 px-10"
                type="submit"
              >
                {{ loading ? 'Loading' : 'Send' }}
              </ui-button>
            </form>
          </validation-observer>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

import VueRecaptcha from 'vue-recaptcha'
import axios from 'axios'

export default {
  name: 'ContactSection',
  components: { DatePicker, VueRecaptcha },
  data() {
    return {
      name: '',
      email: '',
      WhatToBuild: '',
      urls: '',
      startTime: '',
      howSoon: '',
      budget: '',
      loading: false
    }
  },
  mounted() {
    /*     let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute(
      'src',
      'https://unpkg.com/vue-recaptcha@latest/dist/vue-recaptcha.min.js'
    )
    recaptchaScript.async = true
    recaptchaScript.defer = true
    document.head.appendChild(recaptchaScript)

    let recaptchaScript2 = document.createElement('script')
    recaptchaScript2.setAttribute(
      'src',
      'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit'
    )
    recaptchaScript2.async = true
    recaptchaScript2.defer = true
    document.head.appendChild(recaptchaScript2)
 */
  },
  methods: {
    onSubmit() {
      this.$refs.recaptcha.execute()
    },
    async onCaptchaVerified() {
      try {
        this.loading = true
        const response = await axios.post(
          'https://api.emergentlabs.com.ng/contact',
          {
            name: this.name,
            email: this.email,
            build: this.WhatToBuild,
            url: this.urls,
            startTime: this.startTime,
            liveTime: this.howSoon,
            budget: this.budget,
            currency: 'USD'
          }
        )
        this.loading = false
        if (response.data.data) {
          this.name = ''
          this.email = ''
          this.WhatToBuild = ''
          this.urls = ''
          this.startTime = ''
          this.howSoon = ''
          this.budget = ''
          requestAnimationFrame(() => {
            this.$refs.form.reset()
          })
          this.$swal({
            icon: 'success',
            title: response.data.message
          })
        }
      } catch (err) {
        this.loading = false
        if (err.response) {
          this.$swal({
            icon: 'error',
            title: err.response.data.message
          })
        }
      }
    },
    onCaptchaExpired() {
      console.log('Expired')
    }
  }
}
</script>
